.form-masthead {
  padding-bottom: 30px;
  margin-bottom: 10px;
  text-align: center;

  h2 {
    @include text-headline--medium;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  p {
    @include text-body--large;
    margin-top: 12px;
    margin-bottom: 0;
  }
  div {
    p {
      @include text-body--medium;
    }
  }
}
