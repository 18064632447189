.marketing-banner {
  display: flex;
  min-height: 365px;
  justify-content: space-between;
  background: #196664 0% 0% no-repeat padding-box;
  @include border-radius;

  h1 {
    @include text-headline--large;
    width: 75%;
  }

  p {
    @include text-body--large;
    margin-bottom: 50px;
  }

  h1,
  p {
    color: $accent-background;
  }

  .left-side {
    padding: 54px 50px;
    min-height: 365px;
  }

  .main-image {
    width: 324px;
    height: 365px;
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

.slider-control-bottomright {
  right: 0 !important;
  bottom: -56px !important;
  transform: none !important;
  left: unset !important;

  .paging-dot {
    width: 20px;
    height: 20px;
  }

  .paging-dot > circle {
    r: 6px;
    cx: 12px;
    cy: 12px;
  }
}

.paging-item {
  button {
    padding: 0;
  }
}

.ally,
.automatic-rollovers {
  background: #1a2837 0% 0% no-repeat padding-box;
}

.slider-list {
  cursor: default !important;
}
