.user-details-container {
  .add-new-user-btn {
    &:focus {
      background-color: transparent;
      color: $button-outline-text-color;
    }
  }

  .users-details-table-container {
    margin-top: 0;
  }

  .users-details-header {
    display: flex;
    margin-bottom: 8px;
    justify-content: end;
  }

  #users-details-table {
    margin: 0 auto;
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    border-collapse: separate;

    thead {
      position: sticky;
      top: 0;
      z-index: 2;

      th {
        box-shadow: 10px 10px 30px $grey-1;
        &.sortable {
          cursor: pointer;
        }
      }

      tr th {
        &:first-child {
          border-top-left-radius: 1px;
        }
        &:last-child {
          border-top-right-radius: 1px;
        }
      }
    }

    th,
    td {
      border-bottom: 1px solid $grey-1;
    }

    &.has-paginator tbody tr:last-child {
      td:first-child,
      td:last-child {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    th,
    td {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    th:hover,
    td:hover {
      overflow: visible;
      white-space: normal;
    }

    // name tr + td
    th:first-child,
    td:first-child {
      width: 23%;
    }

    // role tr + td
    th:nth-child(2),
    td:nth-child(2) {
      width: 10%;
    }

    // status tr + td
    th:nth-child(3),
    td:nth-child(3) {
      width: 10%;
    }

    // phone tr + td
    th:nth-child(4),
    td:nth-child(4) {
      width: 18%;
    }

    // email tr + td
    th:nth-child(5),
    td:nth-child(5) {
      width: 22%;
    }

    // actions tr + td
    th:nth-child(6),
    td:nth-child(6) {
      width: 17%;
    }
  }
}
