.all-plans {
  .header-bar {
    padding-bottom: 40px;

    &__title {
      margin-bottom: 0;
    }
    .header-bar-page-title {
      @include text-headline--medium;
      margin-bottom: 0;
      padding: 0;
      flex-direction: column;
    }
  }

  .header-bar-content {
    padding: 0 0 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100vw - 120px);
    z-index: 2;
    border-bottom: 2px solid $grey-2;
  }

  .services-agreements-actions {
    display: flex;
    flex-direction: row;
    align-items: center;

    .button-text {
      border: 1px solid $primary-base;
      border-radius: 20px;
      margin-right: 20px;
    }
    svg {
      position: relative;
      top: 3px;
      margin-right: 4px;
    }
  }
}
