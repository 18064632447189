#add-new-payment-form {
  > fieldset {
    legend {
      margin-bottom: 4px;
    }
  }
  .form-column {
    display: flex;
    gap: 20px;

    fieldset {
      width: unset;
      flex-grow: 1;
    }

    .flex-shrink {
      flex: 0 1 20%;
    }
  }
}
#add-new-payment-form-bd {
  > fieldset {
    legend {
      margin-bottom: 4px;
    }
  }
  input {
    width: 100%;
  }
  .form-column {
    display: flex;
    gap: 20px;

    fieldset {
      width: unset;
      flex-grow: 1;
    }

    .flex-shrink {
      flex: 0 1 20%;
    }
  }
}
