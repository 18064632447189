.pci-compliance-container {
  border: 1px solid $grey-2;
  display: flex;
  padding: 10px 14px;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 18px;

  img {
    width: 25%;
    object-fit: contain;
  }

  p {
    padding: 14px 0;
  }
}

.card-holder-text-info {
  margin: 0 0 18px;
}
