/*-----------------------
Fieldset
-----------------------*/
fieldset {
  margin-bottom: 18px;
  position: relative;
  width: 100%;
}

/*-----------------------
Labels
-----------------------*/
label,
legend,
.fake-legend {
  color: $font-base-color;
  display: inline-block;
  @include text-label--medium;
  text-align: left;

  i {
    @include font-circular-regular;
    font-style: italic;
  }
}

/*-----------------------
Inputs
-----------------------*/
#{$all-text-inputs},
select,
textarea {
  color: $grey-6;
  background-color: $white-base;
  border: $input-border-style;
  @include border-radius($input-border-radius);
  box-sizing: border-box;
  appearance: none;
  transition: all 0.3s ease-in-out;
  @include text-body--large;
  margin-bottom: 0;
  @include rem(padding, 13px);
  margin-top: 8px;
  width: 100%;
  -webkit-font-smoothing: antialiased;

  &::placeholder {
    @include text-body--medium;
    color: $grey-5;
    opacity: 1;
  }

  &:focus {
    border-color: $grey-7;
    outline: none;
  }
}

/*-----------------------
Hidden Input
-----------------------*/
.hidden-field {
  height: 0px;
  width: 0px;
}

// Cypress does not provide a way (or I couldn't find it)
// to set the value of a hidden input. This is to `hide`
// the input with type="text"
.really.hidden-field {
  input {
    padding: 0px;
    border: none;
  }
}

/*-----------------------
Input Icon
-----------------------*/
.icon-label {
  position: relative;
  input {
    @include rem(padding-left, 30px);
  }

  i {
    @include position(absolute, 12px null null 10px);
    background-repeat: no-repeat;
    background-size: 15px;
    height: 20px;
    width: 20px;
  }
}

/*-----------------------
Textarea
-----------------------*/
textarea,
textarea[type='text'] {
  height: 100px;
  resize: vertical;
  @include border-radius($input-border-radius);
}

.with-character-count {
  label {
    @include rem(padding-right, 108px);
  }

  .character-count {
    color: $font-base-color;
    float: right;
    @include position(absolute, 0px 0px null null);
    @include text-body--small;
  }
}

/*-----------------------
Select
-----------------------*/
select {
  background-image: url('../images/utility-icons/dropdown.svg');
  background-position: right 10px top 50%;
  background-repeat: no-repeat;
  background-size: 15px;
  border: $input-border-style;
  @include border-radius($input-border-radius);
  background-color: transparent;
  cursor: pointer;
  @include rem(padding, 6px 32px 6px 10px);
  text-overflow: ellipsis;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  option:disabled {
    color: $grey-light;
    font-style: italic;
  }

  .output {
    position: relative;
  }
}

.select-no-border {
  background-image: url('../images/utility-icons/dropdown-white.svg');
  background-position: right 10px top 50%;
  background-repeat: no-repeat;
  background-size: 15px;
  border: none;
  border-color: none;
  background-color: transparent;
  cursor: pointer;
  @include rem(padding-right, 32px);
  text-overflow: ellipsis;
  width: 100%;
  height: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  option:disabled {
    color: $grey-light;
    font-style: italic;
  }

  .output {
    position: relative;
  }
}

/*-----------------------
Checkbox
-----------------------*/
.checkbox {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-end;

  input {
    flex-shrink: 0;
  }

  label {
    cursor: pointer;
    @include rem(margin, 2px 0px 0px);
    line-height: normal;
  }

  .error-message {
    position: absolute;
    bottom: -1rem;
  }
}

input[type='checkbox'] {
  -webkit-appearance: none;
  appearance: none;
  transition: all 0.3s ease-in-out;
  background-color: $white-base;
  border: 2px solid #8f8f8f;
  border-radius: 2px;
  cursor: pointer;
  position: relative;
  @include rem(height, 20px);
  @include rem(width, 20px);
  outline: none;
  display: inline-block;
  @include rem(margin, 0px 8px 0px 0px);
  min-width: 20px;

  &:focus {
    border-color: $grey-main;
    outline: none;
  }

  &:checked {
    border: none;

    &:after {
      background-image: url('../images/checked.svg');
      background-position: center;
      background-repeat: no-repeat;
      content: '';
      display: block;
      transition: all 0.2s ease-in;
      width: 100%;
      height: 100%;
    }
  }
}

/*-----------------------
Search Inputs - fix to box-sizing content-box issue with normalize.scss
-----------------------*/

input[type='search'] {
  height: 24px;
  box-sizing: border-box;
  border: $input-border-style;
}

/*-----------------------
Radio Inputs
-----------------------*/
.radio-inputs {
  width: 100%;

  label {
    cursor: pointer;
    line-height: normal;
    display: block;
  }

  span {
    color: $font-base-color;
    display: inline-block;
    @include rem(margin-top, 3px);
    width: calc(100% - 32px);

    p {
      white-space: normal;
    }
  }

  input[type='radio'] {
    -webkit-appearance: none;
    appearance: none;
    transition: all 0.3s ease-in-out;
    background-color: $white-base;
    border: $border-style;
    border-radius: 2px;
    cursor: pointer;
    position: relative;
    @include rem(height, 20px);
    @include rem(width, 20px);
    outline: none;
    display: inline-block;
    @include rem(margin, 0px 8px 0px 0px);
    border-radius: 20em;
    vertical-align: top;

    &:focus {
      border-color: $grey-main;
      outline: none;
    }

    &:checked {
      position: relative;
      &:after {
        background-color: $primary-base;
        color: $primary-base;
        border: $primary-base;
        content: '';
        display: block;
        height: 14.5px;
        transition: all 0.2s ease-in;
        text-align: center;
        width: 14.5px;
        -webkit-position: absolute;
        -webkit-z-index: 3;
        border-radius: 20em;
        @include vertical-horizontal-center;
      }
    }
  }
}

/*-----------------------
Error
-----------------------*/
.error {
  input,
  textarea,
  select {
    border: 1px solid $red-base;
    background-color: $red-1;

    &:focus {
      border-color: $grey-main;
      outline: none;
      background: none;
    }
  }
}

.error-message,
span.error-message {
  border: none;
  color: $red-base;
  display: inline-block;
  @include rem(margin-top, 8px);
  @include text-body--small;
  transition: all 0.3s ease-in-out;
  &:first-letter {
    text-transform: capitalize;
  }
  border: none;
  padding: inherit;
}

/*-----------------------
Date Picker
-----------------------*/

button.react-datepicker__navigation {
  min-height: auto;
  border-color: transparent;
  border-radius: 0;
  width: 0;
  height: 0;

  &.react-datepicker__navigation--previous {
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid $grey-3;

    &:hover {
      border-right: 10px solid $grey-4;
    }
  }

  &.react-datepicker__navigation--next {
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid $grey-3;

    &:hover {
      border-left: 10px solid $grey-4;
    }
  }
}

.react-datepicker__input-container {
  width: 100%;

  input {
    &::placeholder {
      font-style: normal;
    }
  }
}

/*-----------------------
Color Picker
-----------------------*/
.color-picker {
  .color {
    border: $border-style;
    height: 20px;
    @include position(absolute, 32px null null 10px);
    width: 20px;
  }

  .hex {
    @include position(absolute, 35px null null 38px);
  }

  .color-input {
    @include rem(padding-left, 50px);
  }
}

/*-----------------------
Button Area
-----------------------*/
.button-area {
  button {
    width: 100%;

    @include grid-media($tablet) {
      width: inherit;
    }
  }

  a {
    display: inline-block;
    @include rem(margin-top, 20px);
    text-align: center;
    width: 100%;

    @include grid-media($tablet) {
      @include rem(margin-top, 10px);
      float: right;
      width: inherit;
    }
  }
}

/*-----------------------
Forms
-----------------------*/
form {
  .row {
    margin-bottom: 0px;
  }
}

/*-----------------------
Form Button Container
-----------------------*/

.flow-button-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: $white-base;
  padding: 18px 0;

  .button-text {
    margin-bottom: 0;
  }

  .flow-button-inner {
    width: 850px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .button-primary {
      margin-left: 10px;
    }
  }
}

input:not([type='checkbox']):not([type='radio']):read-only,
input:disabled,
select:disabled,
textarea:disabled {
  background: #ededed;
  border: $border-style;
  cursor: not-allowed;
}

/*-----------------------
Scroll to Field Error
-----------------------*/
form,
fieldset.error {
  scroll-margin-top: $fixed-header-height * 1.25; // Avoids fixed header
}
