.fiduciary-search-form {
  .radio-container-input {
    p {
      @include text-title--medium;
    }
    label {
      @include text-body--large;
      margin-left: 10px;
    }
  }
}
