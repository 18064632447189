span.status {
  display: inline-block;
  white-space: nowrap;
  color: $grey-main;
  @include text-label--small;

  svg {
    vertical-align: text-top;
    margin-right: 7px;
  }

  &.badge--completed,
  &.badge--results-available,
  &.badge--funding-received {
    path {
      fill: $teal-7;
    }
  }
  &.badge--pending-signatures,
  &.badge--in-progress,
  &.badge--processing {
    path {
      fill: $blue-7;
    }
  }
  &.badge--not-received,
  &.badge--failed {
    path {
      fill: $red-7;
    }
  }
  &.badge--in-review {
    path {
      fill: $purple-7;
    }
  }
  &.badge--results-expired,
  &.badge--ready-for-funding {
    path {
      fill: $orange-7;
    }
  }
}

td.status {
  .button-text {
    display: flex;
    @include text-label--small;
  }

  span {
    padding-left: 8px;
  }
}
