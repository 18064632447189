/*-----------------------
Tippy
-----------------------*/

.tooltip {
  cursor: pointer;
  padding: 0;
  margin-left: 5px;
  vertical-align: middle;
}
