.filter-container {
  margin: 0 10px;

  .filter-btn {
    border-left: $border-style;
    @include button;
    align-items: center;
    border-radius: 0;

    display: flex;
    flex-direction: row;
    gap: 3px;
  }
}
