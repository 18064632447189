.addendum-form-container {
  display: flex;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  column-gap: 32px;
  align-items: center;
  padding-top: 16px;
  padding-left: 12%;
  padding-right: 12%;
  font-family: Inter;
}

.form-masthead-text {
  text-align: center;
  display: flex;
  align-items: center;
}

.addendum-new-agreement-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1px;
  padding-top: 12px;
  padding-left: 12%;
  padding-right: 12%;
}

.addendum-new-agreement-text {
  @include text-body--medium;
  font-weight: 400;
  line-height: 20px;
}

.addendum-new-agreement-link {
  @include text-body--medium;
  font-weight: 400;
  padding-left: 10px;
  color: $primary-base;
  cursor: pointer;
  line-height: 20px;
}

.addendum-agreement-error {
  font-weight: 400;
  line-height: 20px;
  color: $red-6;
  padding-left: 12%;
}

#addendumServicesAgreement {
  display: inline;
  margin-top: 10%;
}
