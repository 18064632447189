.products {
  .product-portal {
    margin-bottom: 27px;
  }

  .non-subscribed-products {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px 10px;

    .product-card-container {
      border: $border-style;
      background-color: white;
      @include border-radius;
      padding: 20px;
      min-height: 258px;
      display: grid;
      grid-template-areas: 
        "header"
        "content"
        "footer";
    }

    .product-card-footer {
      grid-area: footer;
    }

    .product-card-header {
      grid-area: header;
      display: flex;
      align-items: center;
      text-align: center;
      padding-bottom: 10px;

      h3 {
        margin-bottom: 0px;
        margin-left: 10px;
      }

      img {
        width: 30px;
        height: 30px;
      }
    }

    .show-more-text {
      grid-area: 'content';
    }
  }

  @media (max-width: 1200px) {
    .non-subscribed-products {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  .product-offering-card {
    padding-bottom: 30px;
  }
}
