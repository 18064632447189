.file-name-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: $border-style;
  border-radius: 6px;
  @include rem(margin-top, 24px);
  @include rem(margin-bottom, 32px);

  .document {
    display: flex;
    align-items: center;
    gap: 20px;
    margin: 10px;

    svg {
      position: relative;
      top: 6px;
    }

    p {
      margin: 0;
    }
  }

  .document-icon-wrapper {
    display: flex;
    justify-content: center;
    border-radius: 6px;
    width: 60px;
    height: 60px;
    position: relative;

    svg {
      path {
        stroke: $icon-stroke;
      }
    }
  }

  .action-button {
    margin: 20px;
  }
}
