.add-addendum-form {
  margin-top: 20px;

  .radio-container-input {
    display: flex;
    flex-direction: column;

    .input-wrapper {
      display: flex;
      align-items: baseline;
    }

    label {
      @include text-body--medium;
      margin-bottom: 8px;
    }

    input[type='radio'] {
      margin-right: 8px;
    }
  }
}
