.flatfile-close-button {
  border-radius: 0 !important;
  top: -40px !important;
  background-color: red !important;
  padding: 10px !important;
  width: 30px !important;
  height: 10px !important;
  position: relative !important;
}

.flatfile_displayAsModal {
  padding: 50px !important;
  height: 100% !important;
  width: 100% !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  justify-content: center;
}

.flatfile_iFrameContainer {
  width: 75vw !important;
  height: 90% !important;
}

.flatfile_button.flatfile_primary {
  background-color: #4553a9 !important;
  &:hover,
  &:active,
  &:focus {
    background-color: #b1bdff !important;
    color: #ffffff !important;
  }
}

.flatfile_iframe-wrapper {
  padding-left: 130px !important;
}
