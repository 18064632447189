.upload-participant-file-view-container {
  .plan-confirmation {
    .notification.error {
      margin-bottom: 12px;
    }
  }

  .notification {
    margin-bottom: 32px;
    align-items: normal;

    &.error {
      margin-bottom: 16px;
    }
  }

  ol.instructions-list {
    counter-reset: list-number;
    margin-top: 28px;
    padding: 0 40px 0 40px;

    li {
      position: relative;
      margin-bottom: 20px;
    }

    li:before {
      counter-increment: list-number;
      content: counter(list-number);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: -35px;
      top: -3px;
      margin-bottom: 10px;
      margin-right: 25px;
      width: 26px;
      height: 26px;
      border-radius: 50%;
      background-color: $primary-base;
      color: $white-base;
    }
  }

  .upload-container {
    margin-bottom: 40px;

    button.upload-file {
      @include rem(margin-top, 10px);
    }

    span.status {
      vertical-align: baseline;
    }
  }

  .discarded-participants {
    .download-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 20px;
      padding-bottom: 40px;

      span {
        @include text-label--medium;
      }

      button {
        @include text-label--medium;
        display: flex;
        align-items: center;
        color: $primary-base;

        &:hover {
          color: $primary-dark;
        }

        svg {
          width: 18px;
          margin-right: 8px;

          path {
            fill: $primary-base;
          }
        }
      }
    }
  }
}
