#all-participants-removed-modal {
  .download-container {
    button {
      svg {
        width: 18px;
        margin-right: 8px;
      }
    }
  }
}
