.card {
  width: 100%;
  height: max-content;
  background: #fff;
  @include border-radius;
  padding: 36px;
  box-shadow: 0px 1px 5px #15223229;

  &:not(:first-of-type) {
    margin-top: 20px;
  }
}

.color-action-card .card {
  background-color: #009d76;
}

.filter-card {
  border: 3px solid transparent;
  cursor: pointer;
  transition: background-color 350ms, border-color 350ms;
  text-align: left;

  &[aria-pressed='true'] {
    background-color: $indigo-1;
    border-color: $primary-base;
  }

  &:focus-visible {
    border-color: transparent;
    outline: $outline-style;
  }

  &[aria-pressed='true']:focus-visible {
    background-color: $indigo-1;
    border-color: $primary-base;
    outline: $outline-style;
  }

  p {
    color: $grey-5;
    @include text-label--medium;
    display: flex;
    align-items: center;
  }

  svg {
    @include rem(margin-right, 5px);

    path {
      fill: $grey-5;
    }
  }

  .count {
    @include text-headline--large;
  }
}

.flex-columns {
  display: flex;
  width: 100%;
  justify-content: space-between;
  @include rem(margin-bottom, 32px);
  gap: 32px;

  .flex-column {
    width: 100%;
  }
}

.product-portal-container {
  @include border-radius;
  border: $border-style;
  background-color: white;
  padding: 20px;
  width: 100%;
  margin-bottom: 15px;

  .product-portal-header {
    display: flex;
    align-items: center;
    text-align: center;

    .product-type {
      @include text-title--large;
      margin-bottom: 0;
      margin-left: 10px;
    }

    a {
      margin-bottom: 0;
    }

    a,
    span {
      margin-left: auto;
    }

    span {
      font-style: italic;
      color: $grey-base;
    }
  }
}
