#aro-success-modal {
  width: 27.5rem;

  .actions {
    display: flex;
    flex-direction: column;
    > button,
    a {
      margin: 0.375rem;
    }
  }
}
