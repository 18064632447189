.paginator-container {
  margin: 0;
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
  padding-bottom: 6px;
  background: $white-base;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;

  nav {
    margin: 10px 20px;
    .next {
      margin-left: 0;
    }
    .prev {
      margin-right: 0;
    }
  }

  .record-count-container {
    margin-top: 8px;
    p {
      @include text-body--small;
      color: #231f20;
    }
  }
}
