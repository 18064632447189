@use 'sass:math';

//REM MIXIN FROM ROLODEX - BELLYCARD

// Strip the unit from a value

@function remove-unit($num) {
  @return math.div($num, $num * 0 + 1);
}

// Mixin

@mixin rem($property, $values) {
  $root: remove-unit(16px);
  $px-values: ();
  $rem-values: ();

  @each $value in $values {
    @if $value == 0 or type-of($value) != 'number' {
      $px-values: append($px-values, $value);
      $rem-values: append($rem-values, $value);
    } @else {
      $unit: unit($value);
      $val: remove-unit($value);

      @if $unit == 'px' {
        $px-values: append($px-values, $val + px);
        $rem-values: append($rem-values, math.div($val, $root) + rem);
      }

      @if $unit == 'rem' {
        $px-values: append($px-values, $val * $root + px);
        $rem-values: append($rem-values, $val + rem);
      }
    }
  }

  @if $rem-values == $px-values {
    #{$property}: $rem-values;
  } @else {
    #{$property}: $px-values;
    #{$property}: $rem-values;
  }
}
