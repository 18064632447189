/*-----------------------
Empty State
-----------------------*/

.empty-state {
  background-color: $white-base;
  @include center;
  @include rem(padding, 30px);
  text-align: center;
  border: $border-style;
  border-top: none;
  height: 100%;

  h2,
  h3 {
    @include text-headline--medium;
  }

  p {
    @include text-body--large;
    color: $grey-5;
  }
}
