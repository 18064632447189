.modal {
  width: 10%;

  h1,
  h2 {
    @include text-headline--medium;
  }

  p {
    text-align: left;
    align-items: center;
  }

  .fieldset {
    align-items: center;
  }

  &.is-active {
    opacity: 1;
    visibility: visible;
  }

  .modal-fade-screen {
    background-color: rgba(0, 0, 0, 0.65);
    @include position(fixed, 0 0 0 0);
    opacity: 1;
    transition: opacity 0.25s ease;
    z-index: 9999999;
  }

  .modal-close {
    background-color: transparent;
    border: none;
    color: $grey-5;
    cursor: pointer;
    padding-right: 0px;
    padding-left: 0px;
    padding-top: 4px;
    @include position(absolute, 10px 20px null null);
    @include rem(font-size, 36px);

    &:focus {
      @include outline-dark;
    }
  }

  .modal-content {
    hr {
      margin: 24px 0;
    }
  }

  .modal-inner {
    @include border-radius;
    background-color: $white-base;
    box-shadow: none;
    max-height: calc(100% - 40px);
    -ms-overflow-style: none;
    @include grid-container;
    @include rem(margin, 20px);
    @include rem(padding, 20px);
    overflow: auto;
    position: relative;
    transition: opacity 0.25s ease;

    @include grid-media($tablet) {
      @include rem(padding, 24px);
      @include vertical-center;
      left: 50%;
      max-height: 500px;
      max-width: 950px;
      margin: auto;
      transform: translateY(-50%) translateX(-50%);
    }

    .button-block {
      text-align: left;
      display: inline;
      float: right;

      button {
        @include rem(margin-top, 10px);
        width: 30%;

        .button-text {
          margin: 12px 12px 0px 0px;
        }

        @include grid-media($tablet) {
          width: inherit;
        }

        & ~ button,
        & ~ a {
          @include grid-media($tablet) {
            @include rem(margin-left, 0px);
          }
        }
      }
    }

    .card {
      box-shadow: none;
      height: 100%;
      width: 100%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .button-block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }

    p,
    li,
    a {
      @include text-body--large;
    }

    p,
    li {
      &.label {
        color: $grey-6;
      }

      color: $grey-5;
    }

    &.modal--success {
      @include rem(width, 536px);
      color: $grey-5;

      h1 {
        @include rem(margin-bottom, 25px);
      }
    }
  }

  .user-form {
    max-width: 600px;
  }

  // action buttons on the bottom
  .form-actions,
  .modal-actions {
    margin-top: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    button,
    a {
      @include text-label--medium;
      min-width: fit-content;
      margin-bottom: 0;
    }
  }

  .form-actions,
  .modal-actions,
  .confirm-modal-actions {
    #cancel-action {
      margin-right: 16px;
    }

    .button-primary {
      padding-left: 24px;
      padding-right: 24px;
    }
  }

  .modal-actions {
    padding-top: 1.375rem;
  }
}

.flatfile-confirm-modal {
  @include rem(width, $modal-width);

  .button-primary {
    min-width: auto;
  }
}
