.accordion {
  margin-bottom: 40px;

  .accordion__item + .accordion__item {
    margin-top: 30px;
  }

  .accordion__heading {
    margin-bottom: 0;
  }

  [hidden] {
    display: none;
  }

  .accordion__panel {
    margin: 12px 0;
    animation: fadein 0.35s ease-in;
  }

  /* -------------------------------------------------- */
  /* ---------------- Animation part ------------------ */
  /* -------------------------------------------------- */

  @keyframes fadein {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}
