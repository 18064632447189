// this class is applied automatically by the TabBar component from lp-components
.horizontal-tabs {
  border-bottom: $border-style;

  li {
    padding-bottom: 0px;
    display: inline-block;
    @include rem(margin, 0px 30px 0px 0px);

    a {
      text-decoration: none;
      @include text-body--large;
    }

    &.active {
      border-bottom: 2px solid $primary-base;
      a {
        color: $primary-base;
      }
    }
  }
}
