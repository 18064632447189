/*-----------------------
Footer
-----------------------*/
.footer {
  grid-area: footer;
  padding-top: 36px;
  padding-bottom: 81px;
  height: max-content;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-top: 50px;
  border-top: 2px solid $grey-2;

  section {
    &.footer-menu {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      margin-bottom: 28px;
      width: 280%;

      * {
        margin-right: 32px;
      }

      button {
        min-height: auto;
        padding: 0;
        border-radius: 0;
      }

      a,
      button,
      .link-default {
        color: $primary-base;
        text-decoration: none;
        @include text-title--medium;
      }
    }
    &.footer-text {
      max-width: 370px;
      p {
        @include text-body--small;
      }
    }
  }
}
