.account-details {
    position: relative;
    margin-left: 8px;
    
    &-icon-btn {
        margin: 0;
        padding: 0;
    }

    &--dropdown {
        background-color: $white-base;
        border: $border-style;
        @include border-radius($input-border-radius);
        box-shadow: 0px 3px 6px #00000029;
        color: $font-base-color;
        display: flex;
        flex-direction: column;
        min-height: 65px;
        min-width: 110px;
        @include rem(padding-inline, 12px);
        @include rem(padding-block, 10px);
        position: absolute;
        top: 0;
        right: -20px;
        z-index: 5;
    }
}
