.search-services-agreement-view {
  .search-services-agreement-form {
    .form-masthead {
      p {
        padding: 0 100px;
      }
    }
  }
  .company-details-container dl {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 50px;
    margin-bottom: 26px;
  }
  .contact-details-container {
    .contact-details {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      justify-content: space-between;
      column-gap: 32px;
      row-gap: 32px;
    }
  }
}
