.sortable-table {
  th.sortable {
    overflow: hidden;
    text-overflow: ellipsis;
    &.order-ascend {
      &::after {
        content: url('../images/utility-icons/sort.svg');
        background-position: right 30px top 50%;
        background-repeat: no-repeat;
        width: 20px;
      }
    }

    &.order-descend {
      &::after {
        content: url('../images/utility-icons/sort.svg');
        background-position: right 30px top 50%;
        background-repeat: no-repeat;
        width: 20px;
        transform: rotate(360deg);
      }
    }
  }

  tbody {
    display: block;
    max-height: 100vh;

    // addresses layout shift due to scrollbar
    // overflow: auto;
    overflow: auto;
    overflow: overlay; // if overlay is not supported, overflow rolls up to auto

    tr {
      display: table;
      width: 100%;
      table-layout: fixed;
    }
  }

  tbody::-webkit-scrollbar {
    width: 15px;
  }

  tbody::-webkit-scrollbar-track {
    background-color: white;
  }

  tbody::-webkit-scrollbar-thumb {
    padding: 0 1px;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-right: 3px solid transparent;
    border-left: 3px solid transparent;
    background: #909090;
    background-clip: padding-box;
    border-radius: 100px;
  }

  tbody::-webkit-scrollbar-button:single-button {
    background-color: white;
    display: block;
    border-style: solid;
    height: 13px;
    width: 15px;
    &:hover {
      cursor: pointer;
    }
  }

  tbody::-webkit-scrollbar-button:single-button:vertical:decrement {
    border-width: 0 8px 6px 8px;
    border-color: transparent transparent #8c8c8c transparent;
  }

  tbody::-webkit-scrollbar-button:single-button:vertical:increment {
    border-width: 6px 8px 0 8px;
    border-color: #8c8c8c transparent transparent transparent;
  }

  thead {
    display: table;
    border: none;
    width: 100%;
    table-layout: fixed;
    border-right: 15px solid $white-base;
    background-clip: padding-box;
  }
}

.table-container {
  position: relative;
  min-height: 400px;

  .paginator-container {
    height: 62px;
    box-shadow: -10px -10px 30px $grey-1;
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .empty-state {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    min-height: 327px; // 400px - 73px (table header height)
    border: none;
  }
}

table {
  thead {
    border-bottom: $border-style;
    letter-spacing: 1px;
  }
}

th,
td {
  border-bottom: $border-style;
  background: white;
  min-height: 300px;
}
