$modal-border-style: 1px solid $grey-1;

#ineligible-plan-modal {
  @include rem(width, 800px);
  @include rem(max-height, 557px);
  overflow-y: hidden;

  .plan-list {
    @include is-flex-between;
    @include rem(margin-bottom, 36px);
    @include rem(margin-top, 24px);

    &__title {
      margin-bottom: 0;
    }
  }

  .form-body {
    overflow-y: auto;
    height: 400px;
  }

  .form-inner {
    margin-right: 1rem;
  }

  .form-actions {
    margin: 1em 50px;
    justify-content: flex-end;
  }

  ul {
    border-top: $modal-border-style;

    li {
      border-bottom: $modal-border-style;
      @include rem(padding, 8px 0);
    }
  }

  form {
    margin-top: 1.4rem;

    label {
      @include rem(font-size, 16px);
    }

    .RadioGroup > div[role='radiogroup'] {
      display: flex;
      margin-top: 8px;
      flex-direction: column;
      gap: 10px;

      & > div {
        display: flex;
        align-items: center;
        flex-basis: 35%;
        @include rem(gap, 5px);
        margin: 0;

        label {
          font-weight: 500;
          cursor: pointer;
        }
      }
    }
  }
}
