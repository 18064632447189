// Typography Mixin
// ========================================

@mixin font-circular-regular {
  font-family: CircularXXWeb-Regular, sans-serif;
  font-weight: 400;
}

@mixin font-circular-medium {
  font-family: CircularXXWeb-Medium, sans-serif;
  font-weight: 500;
}

@mixin font-circular-black {
  font-family: CircularXXWeb-Black, sans-serif;
  font-weight: 900;
}

@mixin font-formula-regular {
  font-family: PPFormula-CondensedRegular, sans-serif;
  font-weight: 400;
}

@mixin font-inter-bold {
  font-family: Inter-Bold, sans-serif;
  font-weight: 700;
}

@mixin font-inter-semi-bold {
  font-family: Inter-SemiBold, sans-serif;
  font-weight: 600;
}

@mixin font-inter-regular {
  font-family: Inter-Regular, sans-serif;
  font-weight: 400;
}

@mixin text-headline--extar-small {
  @include font-inter-semi-bold;
  @include rem(font-size, 14px);
  letter-spacing: 0;
  line-height: 1.43;
  @include rem(margin-bottom, 14px);
}

@mixin text-headline--small {
  @include font-inter-bold;
  @include rem(font-size, 18px);
  letter-spacing: 0;
  line-height: 1.33;
  @include rem(margin-bottom, 18px);
}

@mixin text-headline--medium {
  @include font-inter-bold;
  @include rem(font-size, 24px);
  letter-spacing: 0;
  line-height: 1.33;
  @include rem(margin-bottom, 24px);
}

@mixin text-headline--large {
  @include font-formula-regular;
  @include rem(font-size, 40px);
  letter-spacing: 0;
  line-height: 1.25;
  @include rem(margin-bottom, 32px);
}

@mixin text-title--small {
  @include font-inter-bold;
  @include rem(font-size, 14px);
  letter-spacing: 0;
  line-height: 1.43;
  @include rem(margin-bottom, 14px);
}

@mixin text-title--medium {
  @include font-inter-semi-bold;
  @include rem(font-size, 16px);
  letter-spacing: 0;
  line-height: 1.33;
  @include rem(margin-bottom, 16px);
}

@mixin text-title--large {
  @include font-inter-bold;
  @include rem(font-size, 18px);
  letter-spacing: 0;
  line-height: 1.33;
  @include rem(margin-bottom, 18px);
}

@mixin text-title--extra-large {
  @include font-inter-bold;
  @include rem(font-size, 24px);
  letter-spacing: 0;
  line-height: 1.33;
  @include rem(margin-bottom, 24px);
}

@mixin text-label--small {
  @include font-inter-semi-bold;
  @include rem(font-size, 14px);
  letter-spacing: 0.25px;
  line-height: 1.43;
}

@mixin text-label--medium {
  @include font-inter-bold;
  @include rem(font-size, 16px);
  letter-spacing: 0.25px;
  line-height: 1.33;
}

@mixin text-body--small {
  @include font-inter-regular;
  @include rem(font-size, 14px);
  letter-spacing: 0;
  line-height: 1.43;
  @include rem(margin-bottom, 14px);
}

@mixin text-body--medium {
  @include font-inter-regular;
  @include rem(font-size, 14px);
  letter-spacing: 0;
  line-height: 1.43;
  @include rem(margin-bottom, 14px);
}

@mixin text-body--large {
  @include font-inter-regular;
  @include rem(font-size, 16px);
  letter-spacing: 0;
  line-height: 1.5;
  @include rem(margin-bottom, 16px);
}
@mixin link-body--sm {
  @include font-inter-regular;
  @include rem(font-size, 14px);
  letter-spacing: 0;
  line-height: 1.43;
  @include rem(margin-bottom, 14px);
}
