.permissible-use-form {
  .radio-container-input {
    .additional-options {
      margin-left: 24px;
      p {
        @include text-title--medium;
      }
    }
    .input-wrapper {
      display: flex;
      align-items: baseline;
    }
    label {
      @include text-body--large;
      margin-left: 24px;
    }
    .permissible-questions {
      @include text-title--medium;
    }
  }
  .advanced-death-audit-section {
    @include text-title--medium;
    margin: 55px 0;
  }
}
#checkbox-search {
  margin-top: -18px;
  .confirm-description {
    @include text-body--large;
    margin-bottom: 0;
  }
}
