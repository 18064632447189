/*-----------------------
Table
-----------------------*/
table {
  table-layout: fixed;
  width: 100%;

  thead {
    border-bottom: 2px solid $grey-2;
  }

  th,
  td {
    display: table-cell;
    @include rem(margin, 15px 5px);
    @include rem(padding, 12px 10px);
    color: $grey-main;
    text-align: left;
    vertical-align: middle;
    word-wrap: break-word;

    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    min-width: 100px;
  }

  th:before,
  td:before {
    color: $grey-base;
  }

  tbody tr:last-child td {
    border-bottom: none;
  }

  th {
    width: 100%;
    @include text-label--small;
  }

  td {
    @include text-body--medium;
  }

  th.sortable {
    cursor: pointer;
  }

  tbody {
    overflow: auto;

    tr {
      &.clickable {
        cursor: pointer;
      }
    }
  }

  .currency {
    text-align: right;
  }
}

.table-header,
.table-cell {
  color: $grey-main;
}

.table-header {
  .table-cell {
    @include text-label--small;
  }
}

.table-row {
  .table-cell {
    @include text-body--medium;

    select {
      margin-top: 0;
      padding-left: 0;
      font-size: inherit;
    }
  }
}

/*-----------------------
Link
-----------------------*/
td > a.link-text {
  color: $primary-base;
}
#plan-name-link {
  color: $primary-base;
  @include text-label--small;
}

/*-----------------------
Scrollable
-----------------------*/
.scrollable-table {
  overflow: auto;
}

/*-----------------------
Filter Table Arrows
-----------------------*/
th.sortable {
  &:after {
    display: inline-block;
    @include rem(font-size, 12px);
    @include rem(margin-left, 10px);
  }

  &.order-ascend:after {
    transform: scaleY(-1);
    filter: FlipV;
    -ms-filter: “FlipV”;
  }
  &:not(.order-ascend):not(.order-descend):hover:after {
    background-image: url('../images/utility-icons/sort-white.svg');
    transform: scaleY(-1);
    filter: FlipV;
    -ms-filter: “FlipV”;
  }
}

/*-----------------------
New Results Indicator
-----------------------*/

.status-container-element {
  display: inline-block;
  vertical-align: middle;
  padding: 0px;
}

.indicator-data {
  max-width: 100px;
  min-width: 100px;
}
