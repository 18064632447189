#duplicate-services-agreement-modal {
  @include rem(width, 800px);
  max-width: 100%;
  max-height: 80vh;
  overflow-y: auto;

  table {
    margin-top: 24px;

    th,
    td {
      padding-left: 0px;
      padding-right: 0px;
      &.view-details {
        text-align: right;
      }
    }
  }

  .form-actions {
    @include rem(padding-top, 24px);
  }
}
