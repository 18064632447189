/*-----------------------
Spinner
-----------------------*/

#spinner,
.spinner {
  text-align: center;

  &:after {
    animation: spin 0.5s linear infinite;
    border-radius: 50%;
    border: 3px solid $primary-base;
    border-top-color: $primary-base;
    border-right-color: transparent;
    border-top-color: transparent;
    content: '';
    display: inline-block;
    height: 30px;
    width: 30px;
  }

  &.spinner-full-screen {
    z-index: 1;
    background: $accent-background;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;

    &:after {
      border-width: 10px;
      height: 100px;
      width: 100px;
      position: absolute;
      top: calc(50% - 50px);
      left: calc(50% - 50px);
    }

    &.spinner-full-screen--with-bg {
      opacity: 0.5;
      z-index: 2;
    }
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
