// Override Reach default styles
[data-reach-menu-list] {
  font-size: inherit;
}

[data-reach-menu-item] {
  cursor: pointer;
  display: block;
  @include text-body--large;
  text-decoration: none;
  white-space: nowrap;
  margin-bottom: 0;
  padding: unset;

  &:not(:last-child) {
    padding-bottom: 10px;
  }

  &[data-selected] {
    background: none;
    color: inherit;
  }
}

.action-menu {
  background-color: $primary-base;
  @include border-radius;
  position: relative;

  > button {
    color: white;
    display: flex;
    align-items: center;
    @include rem(padding-left, 10px);
    @include rem(padding-right, 10px);

    &.open {
      svg {
        transform: rotate(180deg);
      }
    }

    svg {
      display: inline-block;
    }
  }
}

.action-menu-dropdown-content {
  position: absolute;
  @include rem(right, -103px);
  @include rem(margin-top, 10px);
  @include rem(padding-inline, 12px);
  @include rem(padding-block, 10px);
  @include border-radius($input-border-radius);
  background-color: $white-base;
  border: $border-style;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  z-index: 3;
  padding: 0;
  .menu-item {
    padding: 10px 40px 10px 10px;
  }
  .menu-item:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .menu-item:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .menu-item:hover {
    background-color: rgba(101, 114, 194, 0.12);
  }
}
