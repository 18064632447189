// Base Typography
// ========================================

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  @include font-circular-regular;
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $font-base-color;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

h1,
div[aria-level='1'] {
  @include text-title--extra-large;
}

h2,
div[aria-level='2'] {
  @include text-headline--small;
}

h3,
div[aria-level='3'] {
  @include text-headline--small;
}

h4,
div[aria-level='4'] {
  @include text-title--large;
}

h5,
div[aria-level='5'] {
  @include text-title--medium;
}

h6,
div[aria-level='6'] {
  @include text-title--small;
}

p {
  color: $font-base-color;
  @include text-body--medium;

  &:last-child {
    margin-bottom: 0;
  }
}

li,
dd {
  color: $font-base-color;
  @include text-body--medium;
  list-style: none;
}

dt {
  @include text-label--medium;
  color: $font-base-color;
  margin-bottom: 10px;
}

dd {
  @include text-title--medium;
  color: $grey-5;
}

a {
  color: $font-base-color;
  cursor: pointer;
  @include text-body--medium;
  text-decoration: underline;
  transition: all 0.5s ease-in-out;
  &:focus,
  &:hover {
    color: $primary-base;
  }
}

strong,
b {
  font-weight: bold;
}

i,
em {
  font-style: italic;
}

u {
  text-decoration: underline;
}

.text-headline--small {
  @include text-headline--small;
}

.text-headline--medium {
  @include text-headline--medium;
}

.text-title--small {
  @include text-title--small;
}

.text-title--medium {
  @include text-title--medium;
}

.text-title--large {
  @include text-title--large;
}

.text-title--extra-large {
  @include text-title--extra-large;
}

.text-label--small {
  @include text-label--small;
}

.text-label--medium {
  @include text-label--medium;
}

.text-body--small {
  @include text-body--small;
}

.text-body--medium {
  @include text-body--medium;
}

.text-body--large {
  @include text-body--large;
}
.link-body--sm {
  @include link-body--sm;
}
