.search-table {
  border-collapse: separate;
  table-layout: fixed;

  th,
  td {
    border-bottom: 1px solid $grey-1;
  }
  th {
    box-shadow: 10px 10px 30px $grey-1;
  }

  &.has-paginator tbody tr:last-child {
    td:first-child,
    td:last-child {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  tbody {
    tr:last-child {
      td {
        border-bottom: $border-style;
      }
    }
  }

  td {
    padding-top: 1rem;
    padding-bottom: 1rem;

    &:last-child {
      border-right: $border-style;
    }
  }

  thead {
    position: sticky;
    z-index: 2;
    border: $border-style;

    tr th {
      vertical-align: bottom;
      @include rem(padding-bottom, 10px);

      &:first-child {
        border-top-left-radius: 1px;
      }

      &:last-child {
        border-top-right-radius: 1px;
      }
    }
  }

  .modal-button {
    padding: 0.15rem;
    height: 0.8125rem;
  }

  .is-minwidth {
    min-width: 7.1875rem;
  }

  .line-clamp-2 {
    .btn-tooltip {
      width: auto;
      height: auto;
      margin-left: 0;
      white-space: inherit;
      text-align: inherit;
      cursor: default;

      span {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }
  }
}
