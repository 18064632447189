/*-----------------------
Sidebar Navigation
-----------------------*/

.sidebar {
  grid-area: menu;
  width: 203px;
  background: #30363D 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #0000000D;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.collapsed {
    width: 88px;

    #sidebar-logo-badge {
      padding: 32px 24px;
    }
  }

  #sidebar-logo-badge {
    padding: 32px;
    img {
      width: 41px;
      height: 27px;
    }
  }

  a {
    &:active {
      border-left-color: green;
      background: #4F545A 0% 0% no-repeat padding-box;
    }
  }

  .sidebar-nav-item {
    display: flex;
    align-items: center;

    width: 100%;
    height: 80px;

    padding-left: 26px;

    color: $white-base;
    text-decoration: none;

    border-left: 6px solid transparent;
    border-bottom: 1px solid #4F545A;
;

    &:hover {
      border-left-color: #4F545A;
      background: #4F545A 0% 0% no-repeat padding-box;
    }

    &:active {
      border-left-color: #4F545A;
      background: #4F545A 0% 0% no-repeat padding-box;
    }

    img {
      height: 24px;
      width: 24px;
      margin-right: 8px;
    }

  }

  .expand-button-area {
    display: flex;
    justify-content: flex-end;
    padding-right: 15px;
    padding-bottom: 15px;
    position: fixed;
    bottom: 0;
    left: 120px;

    button {
      background: transparent;
      border: none;
      cursor: pointer;
      img {
        width: 32px;
        height: 24px;
      }
    }

    &.collapsed {
      justify-content: center;
      padding: 0;
      padding-bottom: 15px;
      left: 0px;
    }
  }
}