$button-hover-border-color: $indigo-3;
$button-outline-text-color: $primary-base;
$button-outline-hover-bg-color: $indigo-1;

$button-disabled-bg-color: $grey-50;
$button-disabled-border-color: $button-disabled-bg-color;
$button-disabled-text-color: $grey-5;

$button-text-bg-color: transparent;
$button-text-border-color: transparent;
$button-text-text-color: $primary-base;
$button-text-hover-bg-color: $button-outline-hover-bg-color;
$button-text-hover-border-color: $button-text-hover-bg-color;
$button-text-hover-text-color: $button-text-text-color;

$button-border-width: 1px;
$button-transition: all 0.5s ease-in-out;

// Buttons Component Styles
// ========================================

// Main Button
@mixin button {
  @include border-radius;
  display: inline-block;
  @include rem(padding, 8px 16px);
  @include text-label--medium;
  transition: $button-transition;
  position: relative;
  text-align: center;
  align-items: center;
  text-decoration: none;
  -webkit-appearance: none;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    text-decoration: none;
  }
}

button:focus-visible {
  outline: -webkit-focus-ring-color auto 1px; // this should be the default, but for some reason it's not picked up unless explicitly defined
}

// Button Styles
@each $button in $buttons {
  $type: nth($button, 1);
  $bg-1: nth($button, 2);
  $border: nth($button, 3);
  $color: nth($button, 4);
  $hover-bg: nth($button, 5);
  $hover-border: nth($button, 6);
  $hover-color: nth($button, 7);

  .button-#{$type},
  button.button-#{$type},
  a.button-#{$type} {
    background-color: $bg-1;
    border: $button-border-width solid $border;
    color: $color;
    @include button;
    align-items: center;
    text-align: center;
    min-height: 38px;
    height: auto;

    &:active,
    &:focus:not(:focus-visible),
    &:hover {
      background-color: $hover-bg;
      border: $button-border-width solid $hover-border;
      color: $hover-color;
    }

    &.is-disabled {
      background-color: $button-disabled-bg-color;
      border: $button-border-width solid $button-disabled-border-color;
      color: $button-disabled-text-color;
      cursor: not-allowed;
    }

    &.is-fullwidth {
      width: 100%;
    }

    &.in-progress {
      color: transparent;
      pointer-events: none;
      position: relative;

      &:after {
        animation: spin 0.5s linear infinite;
        border: 2px solid $color;
        border-radius: 50%;
        border-right-color: transparent;
        border-top-color: transparent;
        content: '';
        display: block;
        height: 20px;
        @include position(absolute, 10px null null null);
        margin: 0 calc((100% - 40px) / 2);
        width: 20px;
      }
    }
  }

  // Links
  .link-#{$type} {
    background-color: transparent;
    border: none;
    color: $bg-1;
    display: inline-block;
    @include text-label--medium;
    transition: all 0.3s ease-in-out;
    padding: 0;
    margin: 0;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      color: $hover-bg;
    }

    &:focus:not(:focus-visible) {
      @include outline-dark;
    }
  }
}

.button-text {
  @include button;
  min-width: auto;
  background-color: $button-text-bg-color;
  border: $button-border-width solid $button-text-border-color;
  color: $button-text-text-color;
  transition: $button-transition;

  &:active,
  &:focus:not(:focus-visible),
  &:hover {
    color: $button-text-hover-text-color;
    background-color: $button-text-hover-bg-color;
    border: $button-border-width solid $button-text-hover-border-color;
  }

  svg {
    margin-right: 0.5rem;
  }
}

.button-print {
  svg {
    .printer_svg__b {
      fill: $icon-fill;
    }
  }
}

.link-text {
  color: $primary-base;
}
