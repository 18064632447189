.services-agreements-filter {
  right: 22px;
  top: 35px;
  @include border-radius;

  .filter-row {
    & > .CheckboxGroup.error {
      span.error-message {
        margin-top: 0;
        margin-bottom: 24px;
      }
    }

    & > fieldset {
      > :first-child {
        max-width: 35%;
      }
    }

    .CheckboxGroup .checkbox {
      width: 32.5%;
      &:nth-child(2n + 4) {
        margin-left: 35%;
      }
    }

    .error {
      flex-wrap: wrap;

      > div {
        width: 75%;
      }

      span.error-message {
        margin-top: 7px;
        width: 100%;
      }
    }
  }
}
