.support-modal_product-types {
  margin-bottom: 10px;

  div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;

    .type-info {
      display: flex;
      flex-direction: column;

      p {
        margin: 0;

        &.email a {
          color: $primary-base;
          text-decoration: none;

          &:hover {
            color: $primary-dark;
          }
        }

        &.phone a {
          color: inherit;
          text-decoration: none;
        }
      }
    }
  }

  .website-support {
    display: block;
    .website-support-phone {
      color: $primary-base;
      margin-bottom: 5px;
    }
    .website-support-schedule {
      margin-bottom: 25px;
    }
    .email-support {
      color: $primary-base;
      text-decoration: none;
      &:hover {
        color: $primary-dark;
      }
    }
  }
}
.support-modal_info {
  h2 {
    margin-bottom: 10px;
  }
}
