/*-----------------------
Pagination
-----------------------*/
.pagination {
  display: table;
  @include rem(margin, 20px auto 0px);

  li {
    float: left;
    @include text-body--medium;
    @include rem(margin, 0px 8px);

    &:last-child {
      margin: 0;
    }

    a {
      border-radius: 2px;
      float: left;
      height: 35px;
      text-decoration: none;
      text-align: center;
      width: 35px;

      &:hover {
        color: $grey-7;
      }
    }

    &.active a,
    a:active {
      background-color: $primary-base;
      border-radius: 4px;
      color: $white-base;

      &:hover {
        background-color: $primary-dark;
        color: $white-base;
      }
    }

    .delimeter {
      display: block;
      @include rem(padding-top, 6px);
    }
  }
}

//With Arrows
@mixin pagination-arrows {
  background-position: top;
  background-size: 100%;
  content: '';
  display: inline-block;
  height: 16px;
  transition: all 0.3s ease-in-out;
  width: 8px;
  vertical-align: sub;
}

.pagination {
  .next a {
    width: auto;

    &:after {
      background: url('../images/utility-icons/arrow-right.svg') no-repeat;
      @include pagination-arrows;
      @include rem(margin-left, 10px);
    }
  }

  .prev a {
    width: auto;

    &:before {
      background: url('../images/utility-icons/arrow-left.svg') no-repeat;
      @include pagination-arrows;
      @include rem(margin-right, 10px);
    }
  }

  .first a {
    width: auto;

    &:before {
      background: url('../images/utility-icons/skip-arrow-left.svg') no-repeat;
      @include pagination-arrows;
      @include rem(margin-left, 10px);
    }
  }

  .last a {
    width: auto;

    &:after {
      background: url('../images/utility-icons/skip-arrow-right.svg') no-repeat;
      @include pagination-arrows;
      @include rem(margin-left, 10px);
    }
  }

  .disabled-link {
    pointer-events: none;
    filter: contrast(0%);
  }
}
