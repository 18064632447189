/*
 * Legal Disclaimer for Web licence
 *
 * Licence to use self-hosted webfonts for displaying dynamic text on specified website domains. Our package includes WOFF and WOFF2 font formats.
 *
 * Should the license page views limit be exceeded the license holder will be required to contact Lineto.com for an upgrade.
 * 
 * It is strictly prohibited to rename the font and to download or use these fonts in any other media. 
 * 
 * These Web fonts are licensed exclusively for the use on the following domain(s) and their subdomains:
 *
 * secure.mtrustcompany.com (monthly pageviews: <50K)
 *
 */

@font-face {
  font-family: 'CircularXXWeb-Black';
  src: url('../fonts/CircularXXWeb-Black.woff') format('woff');
}

@font-face {
  font-family: 'CircularXXWeb-Black';
  src: url('../fonts/CircularXXWeb-Black.woff2') format('woff2');
}

@font-face {
  font-family: 'CircularXXWeb-Bold';
  src: url('../fonts/CircularXXWeb-Bold.woff') format('woff');
}

@font-face {
  font-family: 'CircularXXWeb-Bold';
  src: url('../fonts/CircularXXWeb-Bold.woff2') format('woff2');
}

@font-face {
  font-family: 'CircularXXWeb-BoldItalic';
  src: url('../fonts/CircularXXWeb-BoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'CircularXXWeb-BoldItalic';
  src: url('../fonts/CircularXXWeb-BoldItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'CircularXXWeb-Medium';
  src: url('../fonts/CircularXXWeb-Medium.woff') format('woff');
}

@font-face {
  font-family: 'CircularXXWeb-Medium';
  src: url('../fonts/CircularXXWeb-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'CircularXXWeb-Regular';
  src: url('../fonts/CircularXXWeb-Regular.woff') format('woff');
}

@font-face {
  font-family: 'CircularXXWeb-Regular';
  src: url('../fonts/CircularXXWeb-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'CircularXXWeb-Italic';
  src: url('../fonts/CircularXXWeb-Italic.woff') format('woff');
}

@font-face {
  font-family: 'CircularXXWeb-Italic';
  src: url('../fonts/CircularXXWeb-Italic.woff2') format('woff2');
}

@font-face {
  font-family: 'PPFormula-CondensedBold';
  src: url('../fonts/PPFormula-CondensedBold.woff') format('woff');
}

@font-face {
  font-family: 'PPFormula-CondensedBold';
  src: url('../fonts/PPFormula-CondensedBold.woff2') format('woff2');
}

@font-face {
  font-family: 'PPFormula-CondensedBold';
  src: url('../fonts/PPFormula-CondensedBoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'PPFormula-CondensedBold';
  src: url('../fonts/PPFormula-CondensedBoldItalic.woff2') format('woff2');
}

@font-face {
  font-family: 'PPFormula-CondensedBold';
  src: url('../fonts/PPFormula-CondensedRegular.woff') format('woff');
}

@font-face {
  font-family: 'PPFormula-CondensedBold';
  src: url('../fonts/PPFormula-CondensedRegular.woff2') format('woff2');
}

@font-face {
  font-family: 'PPFormula-CondensedBold';
  src: url('../fonts/PPFormula-CondensedRegularItalic.woff') format('woff');
}

@font-face {
  font-family: 'PPFormula-CondensedBold';
  src: url('../fonts/PPFormula-CondensedRegularItalic.woff') format('woff');
}

@font-face {
  font-family: 'Inter-Black';
  src: url('../fonts/Inter-Black.ttf') format('ttf');
}

@font-face {
  font-family: 'Inter-Bold';
  src: url('../fonts/Inter-Bold.ttf') format('ttf');
}

@font-face {
  font-family: 'Inter-ExtraBold';
  src: url('../fonts/Inter-ExtraBold.ttf') format('ttf');
}

@font-face {
  font-family: 'Inter-ExtraLight';
  src: url('../fonts/Inter-ExtraLight.ttf') format('ttf');
}

@font-face {
  font-family: 'Inter-Light';
  src: url('../fonts/Inter-Light.ttf') format('ttf');
}

@font-face {
  font-family: 'Inter-Medium';
  src: url('../fonts/Inter-Medium.ttf') format('ttf');
}

@font-face {
  font-family: 'Inter-Regular';
  src: url('../fonts/Inter-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'Inter-SemiBold';
  src: url('../fonts/Inter-SemiBold.ttf') format('ttf');
}

@font-face {
  font-family: 'Inter-Thin';
  src: url('../fonts/Inter-Thin.ttf') format('ttf');
}
