table.services-agreements-table {
  border-collapse: separate;
  table-layout: auto;

  th:first-child,
  td:first-child {
    width: unset;
  }
  th,
  td {
    border-bottom: 1px solid #efefef;
  }

  td:first-child {
    width: 20%;
  }
  td {
    height: 6rem;
    width: 20%;

    span.status {
      padding: 0;
    }
  }
  td.status {
    padding: 0;
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 2;

    th {
      cursor: pointer;
      width: auto;
      box-shadow: 10px 10px 30px $grey-1;
    }

    tr th {
      &:first-child {
        border-top-left-radius: 1px;
      }

      &:last-child {
        border-top-right-radius: 1px;
      }

      img {
        margin-left: 0.1875rem;
        cursor: pointer;
        width: 0.875rem;
      }
    }

    .tippy-content {
      @include rem(width, 240px);
      text-transform: none;
    }

    .modal-button {
      margin-left: 3px;
      height: 18px;
      vertical-align: text-top;
      padding: 0;
    }
  }

  &.has-paginator tbody tr:last-child {
    td:first-child,
    td:last-child {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  tbody {
    tr:last-child {
      td {
        border-bottom: 1px solid $grey-1;
      }
    }
  }

  td {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;

    &.status {
      overflow: auto;

      > button {
        svg {
          path {
            fill: $icon-fill;
          }
        }
      }
    }
  }
}
