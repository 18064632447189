.plan-confirmation {
  h2 {
    @include rem(padding-bottom, 14px);
  }

  .plan-information {
    h2 {
      border-bottom: $border-style;
    }

    .plan-information-table-container {
      margin-bottom: 5px;

      .table-header,
      .table-row {
        display: grid;
        align-items: center;
        grid-template-columns: 28% 24% 35%;
      }
      .arrow {
        padding-left: 90px;
      }

      .table-header {
        .table-cell {
          padding: 12px;
          width: 225px;
          margin-bottom: 0;

          &:first-child {
            padding-left: 0;
          }
        }
      }

      .table-row {
        border-top: $border-style;

        .table-cell {
          padding: 6px;
          vertical-align: middle;
          display: table-cell;
          margin-bottom: 0;

          .link-text {
            @include text-label--small;
            color: $primary-base;
            margin-bottom: 0;
            padding-left: 4px;
            font-weight: 400;
            font-family: Inter;
          }
          .warning-text {
            @include text-label--small;
            color: $red-6;
            margin-bottom: 0;
            padding-left: 2px;
            font-weight: 400;
          }

          &:first-child {
            padding-left: 20px;

            span {
              vertical-align: middle;
            }
          }
        }

        fieldset {
          margin-bottom: 0;
        }

        select {
          border: none;
        }
      }
    }
  }
}
