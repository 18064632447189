.search-records-summary-table-container {
  margin-top: 18px;
  isolation: isolate;

  .table-container {
    min-height: auto;
  }

  table {
    display: block;
    position: relative;
    z-index: 1;
    overflow-x: auto;
    table-layout: fixed;
    box-shadow: 1px 2px 30px $grey-1;

    th,
    td {
      box-sizing: content-box;
      border-bottom: 1px solid #efefef;
      min-width: 156px;
      overflow: hidden;
      vertical-align: top;
      padding: 8px 46px 8px 0;
    }
    &::-webkit-scrollbar {
      height: 8px;
    }
    &::-webkit-scrollbar-track {
      background-color: $grey-2;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $grey-6;
      border-radius: 5px;
    }

    th:last-child,
    td:last-child {
      padding-right: 24px;
    }

    th:first-child,
    td:first-child {
      padding-left: 24px;
    }

    thead {
      th {
        width: auto;
        box-shadow: 10px 10px 30px $grey-1;
      }

      tr th {
        &:first-child {
          border-top-left-radius: 1px;
        }

        &:last-child {
          border-top-right-radius: 1px;
        }
      }
    }

    &.has-paginator tbody tr:last-child {
      td:first-child,
      td:last-child {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    tbody {
      tr:last-child {
        td {
          border-bottom: 1px solid $grey-1;
        }
      }
    }

    td {
      padding-top: 15px;
      padding-bottom: 25px;
    }
  }
}
