.participants-filter {
  top: 0;
  top: 35px;
  right: 20px;
}

.services-agreement-participants {
  margin-top: 20px;

  .filter-btn {
    border-left: $border-style;
    padding: 0 10px 0 7px;
    align-items: center;
    height: 28px;
    display: flex;
    flex-direction: row;
    gap: 3px;
    border-radius: 0;
  }
}

.services-agreement-participants-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 0;

  h2 {
    margin-bottom: 0;
  }
}
