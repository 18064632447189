.filter-icon {
  svg {
    display: block; // helps with height calculation and alignment with text
  }

  .filter-applied {
    svg {
      fill: $icon-fill;
    }
  }
}
