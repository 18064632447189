.dropdown-select {
  position: relative;

  .checkbox {
      margin: 10px 0;
  }

  .select-input {
    background-image: url('../images/utility-icons/dropdown.svg');
    background-position: right 10px top 50%;
    background-repeat: no-repeat;
    background-size: 15px;
    background-color: #fff;
    border: $input-border-style;
    border-radius: 5px;
    box-sizing: border-box;
    cursor: pointer;
    appearance: none;
    transition: all 0.3s ease-in-out;
    padding: 7px 13px;
    padding: .5rem .92857rem;

    span {
      border-radius: 1px;
      background: #E8F0F0;
      padding: 6px 8px;
      margin-right: 8px;
    }
  }

  .options.is-active {
    display: block;
    position: absolute;
    top: 2px;
    width: 100%;
  }

  .scroll-box {
    fieldset {
      margin-bottom: 0;
    }
  }
}

.dropdown-select .options, .dropdown .options {
  display: none;
}

.dropdown-select .scroll-box, .dropdown .scroll-box {
  background-color: #fff;
  border: 1px solid #DEDEDE;
  position: absolute;
  top: 41px;
  right: 0px;
  left: 0px;
  z-index: 2;
}

.dropdown-select .options.is-active .scroll-box, .dropdown .options.is-active .scroll-box {
  max-height: 150px;
  overflow: scroll;
}

.dropdown-select .scroll-box>*, .dropdown .scroll-box>* {
  padding: 2px 10px;
  padding: .14286rem .71429rem;
}