.search-details {
  .card-radio-group {
    div[role='radiogroup'] {
      margin-top: 0;
    }
    .fee-waive-text {
      display: flex;
      flex-direction: row;
      p {
        @include text-body--large;
        margin: 4px 8px;
      }
    }
  }
}

.btn-tooltip {
  display: inline-block;
  margin-left: 3px;
  padding: 0;
  width: 1em;
  background-size: 100%;
  letter-spacing: inherit;
  img {
    width: 100%;
  }
}
